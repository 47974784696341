<template>
    <div v-loading="loading" class="upload-container">
        <el-button v-if="btnShow" icon="el-icon-upload" :size="btnSize" :type="btnType" :plain="btnPlain" @click="DialogVisible = !DialogVisible">{{btnMSg}}</el-button>

        <el-dialog title="文件管理" custom-class="upload-dialog" :visible.sync="DialogVisible" :width="width" :close-on-click-modal="true" :close-on-press-escape="false" :append-to-body="true" >
            <!-- 顶部操作栏 -->
            <div class="upload-wrap-inline mb16 clearfix">
                <div class="leval-item">
                </div>
                <div class="leval-item">
                    <el-upload class="avatar-uploader" ref="upload" :action="action" :name="name" :data="Cates.pageData[Cates.active].id?{appname:this.appname,cateid:Cates.pageData[Cates.active].id}:{appname:this.appname}" :accept="accept" :headers="AHeader" :before-upload="ActionBeforUpload" :on-success="ActionUploadSuccess" :multiple="false"  :show-file-list="false" >
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </div>
            </div>

            <!-- 我的资源栏 -->
            <div class="fileContainer">
                <div class="file-type">
                    <ul>
                         <li v-for="(item,index) in Cates.pageData" :key="index" :class="Cates.active==index?'item active':'item'" @click="Cates.active=index,ActionRecord()">
                            {{item.name}}
                            <div class="operation" @click.stop v-if="item.id">
                                <p @click="Cates.pageFrom = item,InnerVisible = true"><!-- 编辑 -->
                                    <i class="el-icon-edit"></i>
                                </p>
                                <p @click="ActionCatesDel(item)"><!-- 删除 -->
                                    <i class="el-icon-delete"></i>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="file-content">
                    <ul class="fileContainerUI clearfix">
                        <li class="file" v-for="(item, index) in Record.pageData" :key="index" @click="selectFileFunc(item)">
                            <div v-show="!!Select[item.id]" class="selectedIcon">
                                <i class="el-icon-check"></i>
                            </div>
                            <img :scr="getPath(item.path)" :style="'background-image:url('+getPath(item.path)+')'" />
                            <p class="desc">{{ item.name }}</p>
                            <div class="bottom-shade" @click.stop>
                                <a href="javascript:void(0)"  @click="ActionRecordDel(item)"><i class="el-icon-delete"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
                <!--分页-->
                <div class="pagination-wrap">
                    <el-pagination :current-page="Record.pageInfo._page" :page-size="Record.pageInfo._size" :total="Record.pageInfo.total" :page-sizes="[6,12,24,36,48,54,60,66,72,78]" layout="total, sizes, prev, pager, next, jumper" @size-change="ActionRecordSizeChange" @current-change="ActionRecordPageChange" />
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="DialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </div>
            </div>

            <el-dialog width="30%" :visible.sync="InnerVisible" :title="(Cates.pageFrom.id?'修改':'添加')+'分类'" :close-on-click-modal="true" :close-on-press-escape="false" :append-to-body="true">
                <el-form ref="form">
                    <el-form-item label="分类名称">
                        <el-input placeholder="请输入分类名称" v-model="Cates.pageFrom.name" />
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="ActionCatesPut(Cates.pageFrom)">保存</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>
 
<script>
const CATES   = 'app-files-cates';
const RECORD  = 'app-files-record';
export default {
    name: "page-files",
    props: {
        action: {
            type: String,
            default: $config.apihost +'/api.php?r='+ RECORD,
        },
        name: {
            type: String,
            default: "upfile",
        },
        accept: {
			type: String,
			default: '.png,.jpg'
        },
        appname:{
            type: String,
			default: ''
        },
        headers:{
            type: Object,
            default(){
                return {}
            }
        },
        limit: {
            type: Number,
            default: 3,
        },
        split: {
			type: String,
			default: ''
        },
        btnShow: {
            type: Boolean,
            default: true,
        },
        btnMSg: {
            type: String,
            default: "文件管理",
        },
        btnPlain: {
            type: Boolean,
            default: false,
        },
        btnSize: {
            type: String,
            default: "small",
        },
        btnType: {
            type: String,
            default: "primary",
        },
        btnMSg: {
            type: String,
            default: "点击上传",
        },
        width: {
            type: String,
            default: "910px",
        },
    },
    data() {
        return {
            loading:false,
            InnerVisible:false,
            DialogVisible: false,
            AHeader:{},
            ALoading:null,
            Cates:{
                active:0,
                pageFrom:{},
                pageData:[
                    {name:'全部'}
                ],
            },

            Record:{
                pageInfo:{
					_page:1,
					_size:24,
				},
                pageData:[],
            },
            Select:{},
        };
    },
    created() {
        this.reset();
    },
    methods: {
        open(){
            this.Select = {},this.DialogVisible = true;
            this.Cates.pageData.length == 1 && this.reset();
            return this;
        },
        close(){
            this.DialogVisible = false,this.InnerVisible = false;
            return this;
        },
        reset(){
            this.AHeader  = Object.assign(this.headers,this.$apimit.permit()),this.ActionCates();
        },

        //上传文件
        ActionBeforUpload(param){
            this.AHeader  = Object.assign(this.headers,this.$apimit.permit());
            this.ALoading = this.$loading({lock: true,text: '图片上传中,请等待',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        },
        ActionUploadSuccess(res) {
            if( res && res.code == 0 ){
                this.ActionCates(), this.$notify({message:'上传成功'});
            }else{
                this.$notify({message:res.msg||'上传失败'})
            }
            this.ALoading.close();
		},

        //分类相关
        ActionCates(){
            this.ActionRecord();
        },

        ActionCatesPut(item){
            item.appname = this.appname;
            this.$save(CATES,item).then(res=>
            {
                this.loading = false, this.InnerVisible = false , this.ActionCates(),this.$notify({message: res.msg || '操作成功'});
            }).catch(err=>
            {
                this.loading = false, this.InnerVisible = false  , this.$notify.error({message: err || '网络请求错误'});
            });
        },
        ActionCatesDel(item){
            if( item.id ){
                this.$confirm('确定要删除目录及文件吗?', '提示', {type: 'warning',confirmButtonText: '确定',cancelButtonText: '取消'}).then(() => {
                    this.loading = true;
                    this.$del(CATES+'/'+item.id).then(res=>{
                        this.loading = false ,this.Select = {}, this.ActionCates() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                })
            }
        },
        
        
        //文件相关
        ActionRecord(){
            this.$get(RECORD,Object.assign(this.Record.pageInfo,{appname:this.appname,cateid:this.Cates.pageData[this.Cates.active].id})).then(res=>{
                this.Record.pageData = res.data,this.Record.pageInfo.total = res.extra.pageInfo.count,this.Record.pageInfo._page = res.extra.pageInfo.page;
            }).catch(err=>{
                this.Record.pageInfo.total = 0,this.Record.pageInfo._page = 1;
            })
        },
        ActionRecordDel(item){
            if( item.id ){
                this.$confirm('确定删除选中的文件吗?', '提示', {type: 'warning',confirmButtonText: '确定',cancelButtonText: '取消'}).then(() => {
                    this.$del(RECORD+'/'+item.id).then(res=>{
                        this.loading = false , this.Select = {}, this.ActionRecord() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                })
            }
        },
        ActionRecordDels(  ){
            let ids = [];
            for(let id in this.Select){
                ids.push(id);
            }
            if( ids.length > 0 ){
                this.$confirm('确定删除选中的文件吗?', '提示', {type: 'warning',confirmButtonText: '确定',cancelButtonText: '取消'}).then(() => {
                    this.$del(RECORD+'/'+ids.join(',')).then(res=>{
                        this.loading = false , this.Select = {}, this.ActionRecord() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                })
            }
        },

        ActionRecordMove(item){
            let ids = [];
            for(let id in this.Select){
                ids.push(id);
            }
            if( ids.length > 0 && item.id ){
                this.$confirm('确定移动选中的文件吗?', '提示', {type: 'warning',confirmButtonText: '确定',cancelButtonText: '取消'}).then(() => {
                    this.$put(RECORD+'/'+ids.join(','),{cateid:item.id}).then(res=>{
                        this.loading = false , this.ActionRecord() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                })
            }
            
        },
        ActionRecordSizeChange(val) {
			this.Record.pageInfo.size = val,this.ActionRecord();
		},
		ActionRecordPageChange(val) {
			this.Record.pageInfo._page = val,this.ActionRecord();
        },
        
        //选择图片
        selectFileFunc(item){
            if( !this.Select[item.id] ){
                if( Object.keys(this.Select).length >= this.limit )
                {
                    this.$notify.error({message:'选择最多不能超过 '+this.limit+' 个'});
                }else{
                    this.$set(this.Select,item.id,item)
                }
            }else{
                this.$set(this.Select,item.id,null),delete this.Select[item.id];
            }
        },

        //拼接路径
        getPath(path){
            return $config.apihost +'/'+ path;
        },

        //确认返回
        confirm(){
            let selects = [];
            for( let id in this.Select ){
                selects.push( this.Select[id].path );
            }
            this.$emit('confirm',this.split==''?selects:selects.join(this.split)),this.InnerVisible = false,this.DialogVisible=false;
        },
    }
};
</script>
 
<style lang="scss" scoped>
.mb16{
    margin-bottom: 16px;
}

.upload-wrap-inline .leval-item {
    display: inline-block;
}

.upload-wrap-inline .upload-btn {
    float: right;
}

.fileContainer {
    position: relative;
    padding-left: 120px;
}

.fileContainer .file-type {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    width: 120px;
}

.fileContainer .file-type li {
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    padding-right: 20px;
    min-height: 20px;
    position: relative;
}

.fileContainer .file-type li.active {
    background: #b4b4b4;
    color: #ffffff;
}

.fileContainer .file-type li .operation {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
}

.fileContainer .file-type li:hover .operation {
    display: block;
}

.fileContainer .file-content {
    height: 300px;
    text-align: center;
    overflow: hidden;
    padding: 10px;
    margin: 0;
    overflow-y: auto;
    border: 1px solid #c6c6c6;
}

.fileContainer li.file {
    float: left;
    margin: 10px 9px 0px;
    position: relative;
    width: 100px;
}

.fileContainer li.file img {
    display: inline-block;
    width: 100px;
    height: 100px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

.fileContainer li.file p.desc {
    font-size: 12px;
    height: 15px;
    line-height: 15px;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.fileContainer li.file:hover .bottom-shade {
    display: block;
}

.fileContainer .bottom-shade {
    position: absolute;
    bottom: 16px;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    height: 26px;
    line-height: 26px;
    width: 100%;
    display: none;
    transform: all 0.2s ease-out 0s;
    color: #ffffff;
}
.fileContainer .bottom-shade a {
    color: #ffffff;
}

.fileContainer .selectedIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: #ff9900;
    z-index: 1;
    color: #ffffff;
    cursor: pointer;
}

// :focus {
//   outline: -webkit-focus-ring-color auto 1px;
// }

.upload-dialog .pagination-wrap {
    margin-top: 16px;
    text-align: right;
}

.move-type {
    max-height: 200px;
    overflow-y: auto;
}
.move-type li {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
}
.move-type li:hover {
    background: #c6e2ff;
}

.dialog-footer{
    padding-top:20px;
    text-align: right;
}

.el-form-item{
    margin-bottom:0px
}

</style>